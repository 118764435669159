var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.$style.containerForm},[_c('div',{staticClass:"card",class:_vm.$style.cardSection},[_c('div',{staticClass:"text-center font-size-24",class:_vm.$style.colorPrimary},[_c('strong',[_vm._v(" "+_vm._s(_vm.$t('authForm.signInTitle'))+" ")])]),_c('div',{staticClass:"mb-2"}),_c('a-form',{staticClass:"mb-2",attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',[_c('label',{class:_vm.$style.labelInput},[_vm._v(_vm._s(_vm.$t('form.emailPhone')))]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'emailPhone',
          {
            rules: [
              { required: true, message: _vm.$t('authForm.requiredEmailPhone') },
            ]
          },
        ]),expression:"[\n          'emailPhone',\n          {\n            rules: [\n              { required: true, message: $t('authForm.requiredEmailPhone') },\n            ]\n          },\n        ]"}],class:_vm.$style.container,attrs:{"size":"large","placeholder":_vm.$t('form.placeholder_email_phone')}})],1),_c('a-form-item',[_c('label',{class:_vm.$style.labelInput},[_vm._v(_vm._s(_vm.$t('form.password')))]),_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:(['password', { rules: [{ required: true, message: _vm.$t('authForm.requiredPassword') }] }]),expression:"['password', { rules: [{ required: true, message: $t('authForm.requiredPassword') }] }]"}],attrs:{"size":"large","placeholder":_vm.$t('form.placeholder_password'),"type":"password"}})],1),_c('a-button',{staticClass:"btn-submit btn-primary text-center w-100",attrs:{"htmlType":"submit","size":"large","loading":_vm.loading}},[_c('strong',[_vm._v(" "+_vm._s(_vm.$t('authForm.signIn'))+" ")])])],1),_c('div',{staticClass:"container-txt"},[_c('router-link',{staticClass:"txt-forgot kit__utils__link font-size-16 mt-2",class:_vm.$style.colorPrimary,attrs:{"to":"/auth/forgot-password"}},[_vm._v(" "+_vm._s(_vm.$t('authForm.forgotPassword'))+" ")]),_c('router-link',{staticClass:"txt-forgot kit__utils__link font-size-16 mt-2",class:_vm.$style.colorPrimary,attrs:{"to":"/auth/register"}},[_vm._v(" "+_vm._s(_vm.$t('authForm.registerNow'))+" ")])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }