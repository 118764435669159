<template>
  <div>
    <cui-login class=" mb-3 mt-5 pt-3"/>
  </div>
</template>
<script>
import CuiLogin from '@/components/cleanui/system/Auth/Login'
export default {
  components: {
    CuiLogin,
  },
}
</script>
