<template>
  <div :class="$style.containerForm">
    <div class="card" :class="$style.cardSection">
      <div class="text-center font-size-24" :class="$style.colorPrimary">
        <strong> {{ $t('authForm.signInTitle') }} </strong>
      </div>
      <div class="mb-2">
      </div>
      <a-form class="mb-2" :form="form" @submit="handleSubmit">
        <a-form-item>
          <label :class="$style.labelInput">{{ $t('form.emailPhone') }}</label>
          <a-input size="large" :placeholder="$t('form.placeholder_email_phone')" :class="$style.container" v-decorator="[
            'emailPhone',
            {
              rules: [
                { required: true, message: $t('authForm.requiredEmailPhone') },
              ]
            },
          ]" />
        </a-form-item>
        <a-form-item>
          <label :class="$style.labelInput">{{ $t('form.password') }}</label>
          <a-input-password size="large" :placeholder="$t('form.placeholder_password')" type="password"
            v-decorator="['password', { rules: [{ required: true, message: $t('authForm.requiredPassword') }] }]" />
        </a-form-item>
        <a-button htmlType="submit" size="large" class="btn-submit btn-primary text-center w-100" :loading="loading">
          <strong> {{ $t('authForm.signIn') }} </strong>
        </a-button>
      </a-form>
      <div class="container-txt">
        <router-link to="/auth/forgot-password" class="txt-forgot kit__utils__link font-size-16 mt-2"
          :class="$style.colorPrimary">
          {{ $t('authForm.forgotPassword') }}
        </router-link>
        <router-link to="/auth/register" class="txt-forgot kit__utils__link font-size-16 mt-2" :class="$style.colorPrimary">
          {{ $t('authForm.registerNow') }}
        </router-link>
      </div>
    </div>
    <!-- <div class="text-center pt-2 mb-auto">
      <span class="mr-2 text-dark"> {{ $t('authForm.dontHaveAccount') }} </span>
      <router-link
        to="/auth/register"
        class="kit__utils__link font-size-16"
        :class="$style.colorPrimary"
      >
        {{ $t('authForm.registerNow') }}
      </router-link>
    </div> -->
    <!-- <div class="divider">
      <a-divider />
    </div> -->
    <!-- <div class="text-center pt-2 mb-auto">
      <img src="https://ik.imagekit.io/fepca/Tentang_OgcWydLE-.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1651126082389" />
      <router-link
        to="/customer-service-contact"
        class="kit__utils__link font-size-16"
        :class="$style.colorPrimary"
      >
        {{ $t('authForm.customerService') }}
      </router-link>
    </div> -->
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'CuiLogin',
  computed: {
    ...mapState(['settings', 'app']),
    ...mapGetters({ loginStatusCode: 'user/loginStatusCode' }),
    loading() {
      return this.$store.state.user.loading
    },
  },
  data: function () {
    return {
      form: this.$form.createForm(this),
    }
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault()
      this.form.validateFields((err, values) => {
        const payload = {
          ...values,
          channel_id: this.$store.state.app.channel_id,
        }
        if (!err) {
          this.$store.dispatch('user/LOGIN', payload)
            .then(({ data, token }) => {
              if (data.change_login_req) {
                this.$router.push({
                  path: '/auth/register',
                  query: { change_login_req: true },
                }).catch(err => console.log({ err }))
              } else {
                this.$router.push('/dashboard').catch(err => console.log({ err }))
              }
            })
            .catch((err) => {
              if (err.response) {
                const { data } = err.response
                this.$notification.error({
                  message: data && data.message ? data.message : 'Internal Server Error',
                })
              }
            })
        }
      })
    },
  },
  watch: {
  },
}
</script>
<style lang="scss" module>
@import '@/components/cleanui/system/Auth/style.module.scss';
</style>
<style lang="scss" scoped>
.logo {
  max-height: 75px;
  max-width: 200px;
}

.divider {
  max-width: 500px;
  margin: auto;
}

.btn-submit {
  background-color: var(--biz-brand-button);
  color: #ffffff;
  border-color: var(--biz-brand-button);
  font-size: 14px;

  @media (min-width: 700px) {
    font-size: 18px;
  }
}

.txt-forgot {
  font-size: 14px;

  @media (min-width: 700px) {
    font-size: 16px;
  }
}

.container-txt {
  display: flex;
  justify-content: space-between;
}

.btn-primary {
  &:hover {
    background-color: #FFFFFF !important;
    color: var(--biz-brand-button)!important;
    border-color: var(--biz-brand-button) !important;
  }
  &:active {
    background-color: #000000 !important;
    color: #FFFFFF !important;
    border-color: #000000 !important;
  }
  &:focus {
    background-color: #000000 !important;
    color: #FFFFFF !important;
    border-color: #000000 !important;
  }
}
</style>
